import React from "react";
import styled from "styled-components";

const Button = styled.button`
  color: limegreen;
  border: 1px solid greenyellow;
  padding: 14px 20px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  width: 250px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:hover {
    color: white;
    background-color: greenyellow;
  }
`;

interface Props {
  onClick: () => void;
}

export const AddQuestion = ({ onClick }: Props) => {
  return <Button onClick={onClick}>+ Add Question</Button>;
};
