import React, { useState } from "react";
import styled from "styled-components";

const NameInput = styled.input`
  text-align: center;
  padding: 10px;
  outline-color: limegreen;
`;

const AddNameContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  width: 100%;
  label {
    font-size: 0.75rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }
`;

interface Props {
  label: string;
  defaultText: string;
  value?: string;
  required: boolean;
  saveName: (name: string) => void;
}

export const AddName = ({
  label,
  defaultText,
  saveName,
  value,
  required,
}: Props) => {
  return (
    <AddNameContainer>
      <label>{label}</label>
      <NameInput
        type="text"
        placeholder={defaultText}
        value={value}
        onChange={(e) => saveName(e.currentTarget.value)}
        required={required}
      />
    </AddNameContainer>
  );
};
