import React from "react";
import Button from "../Button";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import styled from "styled-components";
import { Link } from "gatsby";
import visibility from "../../images/visibility.svg";
import visibility_off from "../../images/visibility_off.svg";

interface Props {
  launchQuiz: (e: React.FormEvent<Element>) => Promise<void>;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
}

const RadioContent = styled.div`
  display: grid;
  img {
    justify-self: center;
  }
`;

const LaunchChecklist = ({ setIsPublic, launchQuiz }: Props) => {
  return (
    <div>
      <h2>Time to launch!</h2>
      <p>Visibility of your poll</p>
      <form onSubmit={launchQuiz}>
        <RadioGroup
          onChange={(value: string) => {
            console.log({ value });
            setIsPublic(value === "public");
          }}
          vertical
          value=""
        >
          <RadioButton rootColor={"#666"} pointColor={"blue"} value={"public"}>
            <RadioContent>
              <img
                style={{ height: `24px`, margin: 0 }}
                src={visibility}
                alt="visibility"
              />
              Public - publicly listed for maximum visibility.
            </RadioContent>
          </RadioButton>
          <RadioButton
            rootColor={"#666"}
            pointColor={"blue"}
            value={"unlisted"}
          >
            <RadioContent>
              <img
                style={{ height: `24px`, margin: 0 }}
                src={visibility_off}
                alt="visibility"
              />
              Unlisted - only accessed directly by URL.
            </RadioContent>
          </RadioButton>
          <Button type="primary" onClick={() => launchQuiz}>
            🚀 Launch Poll
          </Button>
        </RadioGroup>
      </form>
    </div>
  );
};

export default LaunchChecklist;
