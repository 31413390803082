import React from "react";
import Popup from "reactjs-popup";
import { Link } from "gatsby";
import styled from "styled-components";

import { SignIn } from "../SignIn";
import LaunchChecklist from "./LaunchChecklist";
import Button from "../Button";
import rocket from "../../images/rocket.svg";

interface Props {
  launchSuccess: boolean;
  isUserSignedIn: boolean;
  launchError: boolean;
  open: boolean;
  onClose: () => void;
  launchQuiz: (e: React.FormEvent<Element>) => Promise<void>;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledPopup = styled(Popup)`
  @media (max-width: 700px) {
    &-content {
      width: 85% !important;
      .popup-content {
        width: 85% !important;
      }
    }
  }
`;
const PopupContent = styled.div`
  padding: 50px;
  display: grid;
  box-sizing: border-box;
  justify-content: center;
  text-align: center;
  @media (max-width: 700px) {
    padding: 15px;
  }
  img.rocket {
    justify-self: center;
    margin: 20px;
    height: 64px;
    width: 64px;
  }
`;

const LaunchModal = ({
  isUserSignedIn,
  launchQuiz,
  launchSuccess,
  launchError,
  open,
  onClose,
  setIsPublic,
}: Props) => {
  return (
    <StyledPopup open={open} closeOnDocumentClick onClose={onClose}>
      <PopupContent>
        {!isUserSignedIn ? (
          <>
            <h2>Almost there!</h2>
            <SignIn heading={"Sign in to launch your poll!"} />
          </>
        ) : launchSuccess ? (
          <>
            <h2>Success!</h2>
            <img className={"rocket"} src={rocket} alt="rocket" />
            <p>Your poll has launched and is ready to go</p>
            <Link to="/my-polls">
              <Button onClick={() => false} type={"primary"}>
                See My Polls
              </Button>
            </Link>
          </>
        ) : launchError ? (
          <>
            <h2>Oops, something went wrong</h2>
            <Link to="/builder">Try again</Link>
          </>
        ) : (
          <LaunchChecklist setIsPublic={setIsPublic} launchQuiz={launchQuiz} />
        )}
      </PopupContent>
    </StyledPopup>
  );
};

export default LaunchModal;
