import React, { useCallback } from "react";
import { useDropzone, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";

const getColor = (props: DropzoneRootProps, index: number) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return index === 2 ? "dodgerblue" : "orangered";
};

const Container = styled.div<{ rootProps: DropzoneRootProps; index: number }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${({ rootProps, index }) => getColor(rootProps, index)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  p {
    padding: 10px;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 1px solid #666;
  color: #444;
  font-weight: 400;
  margin: 10px 0px;
  padding: 10px;
  font-size: 0.8rem;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

interface Props {
  fileHandler: (file: string) => void;
  index: number;
}

export const StyledDropzone = ({ fileHandler, index }: Props) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    const reader = new FileReader();
    reader.onload = () => {
      const file = reader.result;
      const buffer = Buffer.from(file);
      const imgDataSrc = `data:${
        acceptedFiles[0].type
      };base64, ${buffer.toString("base64")}`;
      fileHandler(imgDataSrc);
    };
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");

    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/*" });

  return (
    <div className="container">
      <Container
        index={index}
        rootProps={getRootProps({ isDragActive, isDragAccept, isDragReject })}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <ImageOutlinedIcon />
        <p>Drop challenger image, or</p>
        <Button onClick={(e) => e.preventDefault()}>Click to Upload</Button>
      </Container>
    </div>
  );
};
