import React, { useContext, useState } from "react";
import { useImmer } from "use-immer";
import styled from "styled-components";
import { v1 } from "uuid";

import { AddQuestion } from "./AddQuestion";
import { AddName } from "./AddName";
import { QuestionEditor } from "./QuestionEditor";
import { newBlankQuestion, newBlankQuiz } from "./helpers";
import { AuthContext } from "../AuthContext";
import LaunchModal from "./LaunchModal";
import { QuizData, Option, Question } from "../interfaces";

const BuilderContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;

  padding: 25px;
  grid-gap: 20px;
  border: 1px dotted #aaa;
  p {
    padding: 25px;
    color: #aaa;
  }
  button {
    align-self: center;
  }
`;

const Controls = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  @media (max-width: 700px) {
    grid-auto-flow: row;
    grid-gap: 20px;
  }
`;

const Button = styled.button`
  background: greenyellow;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.5px;
  width: 250px;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const RootContainer = styled.section`
  display: grid;
  grid-gap: 10px;
  width: 80%;
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const QuizTitle = styled.div`
  width: 100%;
  max-width: 600px;
  h4 {
    margin: 0;
  }
`;

export const BuilderRoot = () => {
  const [quizData, setQuizData] = useImmer<QuizData>(newBlankQuiz());
  const [launchStatus, setLaunchStatus] = useState(null);
  const [launchError, setLaunchError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { auth, saveQuiz } = useContext(AuthContext);
  const [isPublic, setIsPublic] = useState(true);

  const launchQuiz = async (e: React.FormEvent) => {
    e.preventDefault();
    const launchSuccess = await saveQuiz({
      ...quizData,
      createdAt: Date.now(),
      isPublic,
    });
    if (launchSuccess) {
      setLaunchStatus(true);
    } else {
      setLaunchError(true);
    }
    setShowModal(true);
  };
  const addNewQuestion = (uuid: string = v1()) => {
    const newQuestion: Question = newBlankQuestion(uuid);
    setQuizData((draft) => {
      draft.questions = { ...draft.questions, [newQuestion.uuid]: newQuestion };
    });
  };

  const setOptionText = (
    questionUuid: string,
    optionUuid: string,
    newValue: Option["data"]["text"]
  ) => {
    setQuizData((draft) => {
      draft.questions[questionUuid].options[optionUuid].data = {
        ...draft.questions[questionUuid].options[optionUuid].data,
        text: newValue,
      };
    });
  };

  const setOptionImage = (
    questionUuid: string,
    optionUuid: string,
    newValue: Option["data"]["imageUrl"]
  ) => {
    setQuizData((draft) => {
      draft.questions[questionUuid].options[optionUuid].data = {
        ...draft.questions[questionUuid].options[optionUuid].data,
        imageUrl: newValue,
      };
    });
  };

  const saveQuizName = (name: string) => {
    setQuizData((draft) => {
      draft.name = name;
    });
  };

  const updateQuestionTitle = (questionUuid: string, title: string) => {
    const updatedQuestion = { ...quizData.questions[questionUuid], title };
    setQuizData((draft) => {
      draft.questions = { ...draft.questions, [questionUuid]: updatedQuestion };
    });
  };

  const deleteQuestion = (questionUuid: string) => {
    setQuizData((draft) => {
      delete draft.questions[questionUuid];
    });
  };

  return (
    <RootContainer>
      <BuilderContainer
        onSubmit={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        <QuizTitle>
          <h4>
            <AddName
              label={"Quiz Title"}
              saveName={saveQuizName}
              defaultText={`Add your poll title...`}
              value={quizData.name}
              required={true}
            />
          </h4>
        </QuizTitle>
        {Object.keys(quizData.questions)?.length > 0 ? (
          Object.keys(quizData.questions)?.map((question, i) => (
            <QuestionEditor
              key={question}
              saveName={updateQuestionTitle}
              setOptionImage={setOptionImage}
              setOptionText={setOptionText}
              deleteQuestion={deleteQuestion}
              question={quizData.questions[question]}
              index={i}
            />
          ))
        ) : (
          <p>No Questions Yet. Add below.</p>
        )}
        <Controls>
          <AddQuestion onClick={() => addNewQuestion()} />
          <Button type="submit">🚀 Launch Quiz</Button>
        </Controls>
      </BuilderContainer>
      <LaunchModal
        open={showModal}
        isUserSignedIn={auth.isUserSignedIn}
        setIsPublic={setIsPublic}
        launchQuiz={launchQuiz}
        launchSuccess={launchStatus}
        launchError={launchError}
        onClose={() => setShowModal(false)}
      />
    </RootContainer>
  );
};
