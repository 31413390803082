import React from "react";
import styled from "styled-components";

import { Option } from "./BuilderRoot";
import { AddName } from "./AddName";
import { StyledDropzone } from "./StyledDropzone";

interface Props {
  option: Option;
  index: number;
  setOptionText: (
    questionUuid: string,
    optionUuid: Option["uuid"],
    newValue: Option["data"]["text"]
  ) => void;
  setOptionImage: (
    questionUuid: string,
    optionUuid: Option["uuid"],
    newValue: Option["data"]["imageUrl"]
  ) => void;
  questionUuid: string;
  uuid: string;
}

const OptionContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 1fr;
  img {
    max-width: 100%;
  }
`;

const ImgContainer = styled.div<{ index: number }>`
  display: grid;
  place-items: center;
  img {
    max-width: 100%;
  }
  border-right: 10px solid
    ${({ index }) => (index === 2 ? `dodgerblue` : `orangered`)};
  border-bottom: 10px solid
    ${({ index }) => (index === 2 ? `dodgerblue` : `orangered`)};
`;

export const OptionControl = ({
  option,
  setOptionText,
  setOptionImage,
  questionUuid,
  index,
}: Props) => {
  const data = option.data;
  return (
    <OptionContainer>
      {data.imageUrl ? (
        <ImgContainer index={index + 1}>
          <img src={data.imageUrl} />
        </ImgContainer>
      ) : (
        <StyledDropzone
          index={index + 1}
          fileHandler={(file: string) => {
            setOptionImage(questionUuid, option.uuid, file);
          }}
        />
      )}
      <AddName
        label="Description"
        value={data.text}
        defaultText={"Description of thing to compare..."}
        saveName={(val: string) =>
          setOptionText(questionUuid, option.uuid, val)
        }
        required={true}
      />
    </OptionContainer>
  );
};
