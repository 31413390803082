import { v1 } from "uuid";
import { QuizData, Question } from "../interfaces";

export const newBlankQuiz = (uuid: string = v1()): QuizData => {
  const starterQuestionUuid = v1();
  return {
    uuid,
    name: "",
    questions: {
      [starterQuestionUuid]: newBlankQuestion(starterQuestionUuid),
    },
    status: "open",
    isPublic: true,
    createdAt: Date.now(),
  };
};

export const newBlankQuestion = (uuid: string = v1()): Question => {
  const firstOptionUuid = v1();
  const secondOptionUuid = v1();
  return {
    uuid,
    title: "",
    options: {
      [firstOptionUuid]: {
        uuid: firstOptionUuid,
        data: { imageUrl: "", text: "" },
      },
      [secondOptionUuid]: {
        uuid: secondOptionUuid,
        data: { imageUrl: "", text: "" },
      },
    },
  };
};

/**
 * Convert to Base64
 */
export const convertToBase64 = (file: File): Promise<string | undefined> =>
  new Promise((resolve, reject) => {
    if (!file) {
      resolve();
    }
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve(reader.result ? reader.result.toString() : undefined);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });
