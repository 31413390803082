import React from "react";
import styled from "styled-components";

import { BuilderRoot } from "../components/quiz-builder/BuilderRoot";

import { Layout } from "../layouts/Layout";
import SEO from "../components/Seo";

const PageHeader = styled.section`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  margin-bottom: 25px;
  h2 {
    margin: 0;
    justify-self: center;
  }
`;

const PageContent = styled.section`
  display: grid;
  place-items: center;
`;

export const QuizBuilderPage = ({ location }: any) => {
  return (
    <Layout>
      <SEO title="Builder" />
      <PageHeader>
        <h2>Poll Builder</h2>
      </PageHeader>
      <PageContent>
        <BuilderRoot />
      </PageContent>
    </Layout>
  );
};

export default QuizBuilderPage;
