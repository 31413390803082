import React from "react";
import styled from "styled-components";
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined";

import { AddName } from "./AddName";
import { OptionControl } from "./OptionControl";
import { Question, Option } from "../interfaces";

interface Props {
  question: Question;
  index: number;
  saveName: (questionUuid: string, name: string) => void;
  setOptionText: (
    questionUuid: string,
    optionUuid: Option["uuid"],
    newValue: Option["data"]["text"]
  ) => void;
  setOptionImage: (
    questionUuid: string,
    optionUuid: Option["uuid"],
    newValue: Option["data"]["imageUrl"]
  ) => void;
  deleteQuestion: (questionUuid: string) => void;
}

const QuestionEditorContainer = styled.div`
  display: grid;
  position: relative;
  padding: 25px 50px;
  width: 80%;
  box-sizing: border-box;
  @media (max-width: 700px) {
    padding: 20px;
    width: 100%;
  }
  box-shadow: 0px 2px 15px 3px rgba(0, 0, 0, 0.1);
  p {
    margin: 5px 0px;
  }
  h4 {
    margin: 0;
  }
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  @media (max-width: 700px) {
    grid-template-columns: auto;
    grid-template-rows: 1fr 60px 1fr;
  }
  padding-top: 25px;
  > span {
    place-items: center;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    align-self: center;
  }
`;

const DeleteButton = styled(DeleteForeverOutlined)`
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
  color: #ccc;
  cursor: pointer;
  &:hover {
    color: tomato;
  }
`;

export const QuestionEditor = ({
  question,
  saveName,
  setOptionText,
  setOptionImage,
  deleteQuestion,
  index,
}: Props) => {
  const option1uuid = Object.keys(question.options)[0];
  const option2uuid = Object.keys(question.options)[1];
  return (
    <QuestionEditorContainer>
      <DeleteButton onClick={() => deleteQuestion(question.uuid)} />
      <h4>
        {
          <AddName
            label={`Question ${index + 1} Title`}
            saveName={(name: string) => saveName(question.uuid, name)}
            defaultText={`Add your question...`}
            value={question.title}
            required={true}
          />
        }
      </h4>
      <OptionsContainer>
        <OptionControl
          option={question.options[option1uuid]}
          uuid={option1uuid}
          index={0}
          setOptionText={setOptionText}
          setOptionImage={setOptionImage}
          questionUuid={question.uuid}
        />
        <span>VS.</span>
        <OptionControl
          option={question.options[option2uuid]}
          uuid={option2uuid}
          index={1}
          setOptionText={setOptionText}
          setOptionImage={setOptionImage}
          questionUuid={question.uuid}
        />
      </OptionsContainer>
    </QuestionEditorContainer>
  );
};
